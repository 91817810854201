<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-body pa-2 d-flex">
      <div class="row m-0">
        <div
          v-for="(item, key) in items"
          v-show="key !== '0'"
          :key="key"
          class="col-4 p-sm-4 p-2"
        >
          <a
            href="javascript:void(0);"
            class="section d-block px-4 py-2 px-sm-6 py-sm-4 rounded-lg cursor-pointer h-100 border border-secondary"
            @click="selectMenu(item.route)"
          >
            <v-icon
              class="d-block icon-3x text-secondary mb-3"
              style="line-height: 1"
            >
              {{ item.icon }}
            </v-icon>
            <div class="text-secondary font-size-h5" style="line-height: 1">
              {{ $t(item.label) }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import MenuService from "@/core/services/menu.service";

export default {
  name: "ProjectsWelcomeAppsSections",
  props: {
    userType: String
  },
  data: function () {
    return {
      items: []
    };
  },
  mounted() {
    this.items = MenuService.getItems()[0]["children"];
  },
  methods: {
    selectMenu(routeName) {
      let route = routeName;

      if (route === "projectReportings") {
        route = "projectReportingJobs";
      }

      const data = {
        area: MenuService.getItems()[0],
        routeName: route
      };
      bus.$emit("subMenuAreaChanged", data);
    }
  }
};
</script>

<style lang="scss">
.section:hover {
  &.border-secondary,
  .text-secondary {
    border-color: #ff3554 !important;
    color: #ff3554 !important;
  }
}
</style>
