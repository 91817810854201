<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-body">
      <h1 v-if="selectedProject && selectedProject.name">
        {{ $t("dashboard.welcomeToProject") }}:
        {{
          selectedProject && selectedProject.name ? selectedProject.name : ""
        }}!
      </h1>
      <h1 v-else>{{ $t("dashboard.welcomeToProject") }}!</h1>
      <p>{{ $t("dashboard.projectShortDescription") }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProjectsWelcomeAppsWelcome",
  computed: {
    ...mapGetters(["selectedProject"])
  }
};
</script>
