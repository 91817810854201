<template>
  <div
    class="card card-custom grid-stack-item-content"
    style="
      cursor: move;
      background-position: right top;
      background-size: 30% auto;
      background-image: url('/media/svg/shapes/abstract-2.svg');
    "
  >
    <div class="card-body">
      <div class="d-flex justify-content-between flex-column pt-2 h-50">
        <div class="pb-1">
          <div class="d-flex flex-column flex-center">
            <div class="symbol symbol-100">
              <img
                :src="partner.logo ? partner.logo : '/media/users/blank.png'"
                alt=""
                class="symbol-label"
                style="height: auto; background-color: transparent"
              />
              <i class="symbol-badge bg-success"></i>
            </div>
            <div
              class="card-title font-weight-bolder text-dark-75 font-size-h4 m-0 pt-7 pb-1"
            >
              {{ partner.name ? partner.name : $t("general.noPartner") }}
            </div>
            <div class="font-weight-bold text-dark-50 font-size-sm pb-6">
              {{ $t("general.yourPartner") }}
            </div>
          </div>
          <div class="pt-1">
            <div
              v-if="partner.contact && partner.contact.phone"
              class="d-flex align-items-center pb-9"
            >
              <div class="symbol symbol-45 symbol-light mr-4">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-dark-50">
                    <i class="fal fa-phone" style="font-size: 24px"></i>
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <div class="text-dark-75 mb-1 font-size-lg font-weight-bolder">
                  {{ $t("general.phone") }}
                </div>
                <span class="text-muted font-weight-bold">{{
                  partner.contact.phone
                }}</span>
              </div>
            </div>
            <div
              v-if="partner.contact && partner.contact.email"
              class="d-flex align-items-center pb-9"
            >
              <div class="symbol symbol-45 symbol-light mr-4">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-dark-50">
                    <i class="fal fa-at" style="font-size: 24px"></i>
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <div class="text-dark-75 mb-1 font-size-lg font-weight-bolder">
                  {{ $t("general.email") }}
                </div>
                <span class="text-muted font-weight-bold">{{
                  partner.contact.email
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="partner.contact && partner.contact.email"
          class="d-flex justify-content-between flex-column pt-2 h-50"
        >
          <a :href="'mailto:' + partner.contact.email" class="btn btn-primary">
            {{ $t("general.sendMessage") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Tenants/Users/users";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "GeneralDashboardAppsClientsPartner",
  data() {
    return {
      partner: {
        logo: "",
        contact: {}
      }
    };
  },
  computed: {
    ...mapGetters(["selectedPartner", "selectedClient"])
  },
  watch: {
    selectedPartner: function () {
      this.loadMainContactPerson();
    },
    selectedClient: function () {
      this.loadMainContactPerson();
    }
  },
  mounted() {
    this.loadMainContactPerson();
  },
  methods: {
    loadMainContactPerson() {
      if (
        !ApiService.getHeader("x-api-key") ||
        ApiService.getHeader("x-api-key") ===
          ApiService.getHeader("x-partner-key")
      ) {
        return;
      }

      Users.getPartnerContact()
        .then(response => {
          this.partner = response.data.data;
          if (!this.partner.logo || !this.partner.logo.assetData) {
            this.partner.logo = "/media/users/blank.png";
          } else {
            this.partner.logo =
              "data:" +
              this.partner.logo.assetData.mimeType +
              ";base64," +
              this.partner.logo.assetData.base64;
          }
        })
        .catch(error => {
          this.partner = {
            logo: "",
            contact: {}
          };
          console.log(error);
        });
    }
  }
};
</script>
